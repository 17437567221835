/* eslint-disable arrow-body-style */
import { FC } from 'react'
import Image from 'next/image'
import { Modal, Stack, Text } from '@mantine/core'
import loadingWalletConnect from 'public/svg/loading-wallet/wallet_connect_hq.svg'
import loadingMetaMask from 'public/svg/loading-wallet/metamask_hq.svg'
import classes from './SignWalletRequestModal.module.css'

interface ISignWalletRequestModalProps {
  isOpen: boolean
  loginMethod: string
}

const SignWalletRequestModal: FC<ISignWalletRequestModalProps> = ({ isOpen, loginMethod }) => {

  if (!isOpen) return null 
  
  const handleOnClose = () => null

  return (
    <Modal radius="lg" opened={isOpen} onClose={handleOnClose} centered withCloseButton={false} size="auto">
      <Stack gap={32} maw={500} p={16} justify="center" align="center">
        <Image src={loginMethod === 'metamask' ? loadingMetaMask : loadingWalletConnect} alt="loading wallet" />

        <Text className={classes.responsiveText}>
          Please sign the connection request received on your wallet to verify.
        </Text>
      </Stack>
    </Modal>
  )
}

export default SignWalletRequestModal
