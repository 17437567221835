import React from 'react'
import Image from 'next/image'
import { Box } from '@mantine/core'
import { INTRO_COPY } from './intro-copy'
import OrgInfoRow from '@/views/Organization/components/OrgInfoRow'

interface IIntroCardProps {
  className?: string
  bgColor?: string
}

const IntroCard: React.FC<IIntroCardProps> = ({ className, bgColor = 'bg-grey-900' }) => (
  <Box className={`${bgColor} ${className}`}>
    <Image src="/svg/logos/hq-logo-2.svg" width={200} height={24} alt="hq-img" />

    <div className="h-fit mt-8">
      {Object.keys(INTRO_COPY).map((key) => (
        <OrgInfoRow key={key} {...INTRO_COPY[key]} />
      ))}
    </div>
  </Box>
)

export default IntroCard
