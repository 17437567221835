import Doc from '@/public/svg/org-doc-icon.svg'
import Member from '@/public/svg/icons/member-icon.svg'
import Payment from '@/public/svg/icons/payment-icon.svg'

export const INTRO_COPY = {
  bookeeping: {
    img: Doc,
    title: 'Bookkeeping',
    description: 'Easily sync your on-chain transaction data with your accounting software like Quickbooks and Xero.',
    className: 'mt-0 mb-8',
    titleClassName: 'font-bold text-base leading-5',
    titleColor: 'gray',
    descriptionClassName: 'font-normal text-sm leading-5',
    descriptionColor: 'gray'
  },
  bulkPayouts: {
    img: Payment,
    title: 'Bulk Payouts',
    description: 'Send assets to multiple addresses all in one transfer.',
    className: 'mt-0 mb-8',
    titleClassName: 'font-bold text-base leading-5',
    titleColor: 'gray',
    descriptionClassName: 'font-normal text-sm leading-5',
    descriptionColor: 'gray'
  },
  teamWorkflows: {
    img: Member,
    title: 'Team Workflows',
    description: 'Add your team members and vendors, assign access controls, create contacts and more!',
    className: 'mt-0 mb-0',
    titleClassName: 'font-bold text-base leading-5',
    titleColor: 'gray',
    descriptionClassName: 'font-normal text-sm leading-5',
    descriptionColor: 'gray'
  }
}
